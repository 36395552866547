import type { CustomFlowbiteTheme } from 'flowbite-react';

/**
 * default theme: https://github.com/themesberg/flowbite-react/blob/main/src/lib/theme/default.ts
 * usage: https://flowbite-react.com/theme
 */

const textInputBaseTheme =
  'block w-full rounded-lg border disabled:cursor-not-allowed disabled:opacity-50 resize-none text-base focus:!outline-none focus:ring-transparent';

export const customFlowbiteTheme: CustomFlowbiteTheme = {
  avatar: {
    root: {
      bordered: 'p-[1px] ring-1',
      color: {
        light: 'ring-gray-100 dark:ring-gray-500',
      },

      rounded: '!rounded-full object-cover',
      size: {
        xs: 'w-6 h-6 text-sm',
        sm: 'w-8 h-8 text-sm',
        lg: 'w-16 h-16',
        xl: 'w-24 h-24',
      },
      initials: {
        text: 'font-medium text-primary-600 dark:text-primary-300 mt-0.5',
        base: 'flex overflow-hidden relative justify-center items-center bg-primary-100 dark:bg-primary-600',
      },
    },
  },
  badge: {
    root: {
      base: 'flex items-center gap-1 font-medium flex h-fit items-center gap-1 rounded-lg p-1.5 p-1 text-sm',
      color: {
        blue: 'bg-blue-50 text-blue-600',
        failure: 'bg-red-50 text-red-600',
        warning: 'bg-yellow-50 text-yellow-800',
        gray: 'bg-gray-100 text-gray-500',
      },
    },
    icon: {
      on: 'flex h-fit items-center gap-1 font-semibold rounded-lg p-1.5 p-1 text-xs',
    },
  },
  buttonGroup: {
    position: {
      // remove focus ring for buttons
      none: '',
    },
  },
  button: {
    base: 'group flex h-min items-center justify-center p-0 text-center font-medium focus:z-10 focus:!ring-0',
    size: {
      round: 'px-1.5 py-1.5',
      sm: 'text-sm px-3 py-1.5 leading-5',
      md: 'text-sm px-3 py-2 leading-5',
    },
    color: {
      lightInfo:
        'text-primary-600 bg-gray-50 border border-gray-100 hover:bg-gray-100 disabled:hover:bg-blue-50 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 dark:disabled:hover:bg-blue-600',
      transparent:
        'text-gray-500 bg-transparent hover:bg-gray-50 disabled:hover:bg-white dark:bg-gray-600 dark:text-white dark:hover:bg-gray-700',
      light:
        'text-gray-900 bg-white border border-gray-300 hover:bg-gray-100 disabled:hover:bg-white dark:bg-gray-600 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700',
      gray: 'bg-gray-50 text-gray-900 border border-gray-300 hover:bg-gray-100 disabled:hover:bg-gray-50 focus:text-blue-700 dark:bg-transparent dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 dark:disabled:hover:bg-gray-800 group flex h-min items-center justify-center p-0.5 text-center font-medium focus:z-10 rounded-lg',
    },
  },
  checkbox: {
    root: {
      base: 'h-4 w-4 rounded border border-gray-300 bg-gray-100 dark:border-gray-600 dark:bg-gray-700 focus:ring-0 focus:ring-offset-0',
    },
  },
  toggleSwitch: {
    root: {
      base: 'group relative flex items-center rounded-lg focus:outline-none h-5',
    },
    toggle: {
      base: 'toggle-bg h-5 w-10 rounded-full border group-focus:ring-4 group-focus:ring-blue-500/25 after:h-4 after:w-4 after:rounded-full after:shadow-sm after:transform after:transition after:duration-100 after:ease-in-out after:left-[0.3rem]',
    },
  },
  dropdown: {
    floating: {
      base: 'z-10 w-fit rounded-lg divide-y border-none divide-gray-100 shadow transition-opacity duration-100  bg-white text-gray-900 dark:border-none dark:bg-gray-700 dark:text-white',
      item: {
        icon: 'mr-2 h-4 w-4 text-gray-500',
        base: 'flex items-center justify-start py-2 px-4 text-base text-gray-700 font-normal cursor-pointer hover:bg-gray-50 dark:text-gray-200 dark:hover:bg-gray-600 dark:hover:text-white',
      },
    },
  },
  label: {
    root: {
      base: 'text-gray-900 dark:text-gray-300 mb-2 text-sm',
    },
  },
  modal: {
    content: {
      inner:
        'relative rounded-lg bg-white shadow dark:bg-gray-700 flex flex-col max-h-[90vh] overflow-y-auto',
    },
    body: {
      base: 'px-6 pb-6 pt-5',
    },
    header: {
      base: 'flex items-start justify-between rounded-t px-6 pt-6',
      title: 'text-xl font-medium text-gray-900 dark:text-white',
      close: {
        base: 'ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-900 hover:bg-gray-200 dark:hover:bg-gray-600',
      },
    },
    root: {
      base: 'fixed z-50 inset-0 overflow-y-auto !items-start !pt-20',
      show: {
        on: 'flex bg-gray-900 bg-opacity-30 dark:bg-opacity-80',
      },
    },
  },
  sidebar: {
    item: {
      icon: {
        base: 'h-5 min-w-[1.25rem] block fill-gray-500 mr-2',
        active: 'fill-primary-600',
      },
      content: {
        base: 'flex-1 truncate items-center flex',
      },
      active: 'bg-primary-50 !text-primary-600',
      base: 'h-9 flex items-center rounded-lg p-2 text-base font-medium text-gray-900 hover:bg-gray-50 dark:text-white dark:hover:bg-gray-700',
    },
    itemGroup:
      'mt-4 !space-y-1 border-t border-gray-200 pt-4 first:mt-0 first:border-t-0 first:pt-0 dark:border-gray-700',
    collapse: {
      label: {
        icon: 'hidden',
      },
      icon: {
        open: {
          on: 'h-6 w-6 text-primary-600 transition duration-75  dark:text-gray-400 text-gray-900',
        },
        base: 'h-6 w-6 text-gray-500 transition duration-75  dark:text-gray-400 text-gray-900',
      },
      button:
        'group flex w-full items-center rounded-lg p-2 text-base font-normal text-gray-900 transition duration-75 hover:bg-blue-50 dark:text-white dark:hover:bg-gray-700',
    },
  },
  tab: {
    base: '',
    tablist: {
      base: 'flex my-auto mb-4',
      styles: {},
      tabitem: {
        base: 'transition duration-300 flex my-auto text-base justify-around px-4 py-2 focus:outline-none focus:ring-transparent',
        styles: {},
        icon: 'h-4 w-4 my-auto mr-1',
      },
    },
    tabpanel: 'focus:outline-none focus:ring-transparent',
  },
  textInput: {
    field: {
      icon: {
        svg: 'h-4 w-4 fill-gray-500',
      },
      input: {
        base: `${textInputBaseTheme} py-2 focus:outline-0 overflow-y-auto`,
        sizes: {
          sm: 'px-4 py-0 text-sm',
          md: 'px-4 py-2 text-base leading-5',
          lg: 'px-4 py-2.5 text-lg leading-5',
        },
        colors: {
          light:
            'text-gray-900 placeholder-gray-500 bg-gray-50 rounded-lg border-gray-300 focus:border-primary-500 focus:outline-0',
          gray: 'text-gray-900 placeholder-gray-500 bg-gray-50 rounded-lg border-gray-200 focus:border-primary-500 focus:outline-0',
        },
      },
    },
  },
  helperText: {
    root: {
      base: 'text-xs text-gray-500 mt-1',
    },
  },
  textarea: {
    base: `${textInputBaseTheme} py-3`,
  },
  tooltip: {
    base: 'absolute inline-block z-10 rounded py-2 px-3 text-sm font-normal shadow-lg drop-shadow-md',
    style: {
      auto: 'bg-white text-gray-500 dark:bg-gray-700 dark:text-white',
    },
  },
};
